import {on} from 'delegated-events'

on(
  'details-menu-selected',
  '.js-select-plan-menu',
  function (event) {
    const item = event.detail.relatedTarget
    const sections = document.querySelectorAll('.js-plan-card-section')
    for (const section of sections) {
      if (section instanceof HTMLElement) section.hidden = true
    }

    const items = event.currentTarget.querySelectorAll('[role^=menuitem]')
    const index = Array.from(items).indexOf(item)

    const section = Array.from(sections)[index]
    if (section instanceof HTMLElement) section.hidden = false
  },
  {capture: true}
)
